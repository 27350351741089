import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import { Container } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import uuid4 from "uuid4";
import BlogCard from "../components/blog-card";

const AllProjectsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "all-projects" }) {
        title
        slug
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
      }
      allWpProject {
        nodes {
          title
          uri
          slug
          projectFields {
            projectFeatureImage {
              altText
              localFile {
                publicURL
                childImageSharp {
                  original {
                    height
                    width
                  }
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          pageFieldsGroup {
            pageFieldsGroup {
              ... on WpProject_Pagefieldsgroup_PageFieldsGroup_SectionLayout12 {
                description
                fieldGroupName
              }
            }
          }
        }
      }
    }
  `);

  const { site, pageData, allWpProject } = data;
  const { seoFieldGroups } = pageData;

  const siteUrl = site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "All Projects",
        item: {
          url: `${siteUrl}/all-projects`,
          id: `${siteUrl}/all-projects`,
        },
      },
    ],
  };

  const projectDesc = (pageData) => {
    const { pageFieldsGroup } = pageData;
    
    if (pageFieldsGroup && pageFieldsGroup.length > 0) {
      const projectDetail = pageFieldsGroup.filter(
        (item) =>
          item.fieldGroupName ===
          "Project_Pagefieldsgroup_PageFieldsGroup_SectionLayout12"
      )[0];
      return projectDetail.description;
    }
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          <section className={`my-5 my-md-6 my-xl-8`}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={9} className={`text-center`}>
                  <h2 className="mb-4">projects</h2>
                </Col>
              </Row>
              {allWpProject && allWpProject.nodes.length > 0 && (
                <>
                  <Row className="mt-md-4 g-40">
                    {allWpProject.nodes.map((item) => (
                      <BlogCard
                        title={item.title}
                        image={
                          item.projectFields.projectFeatureImage?.localFile
                            .childImageSharp.gatsbyImageData
                        }
                        uri={item.uri}
                        excerpt={projectDesc(item.pageFieldsGroup)}
                        imageAlt={
                          item.projectFields?.projectFeatureImage.altText
                        }
                        key={uuid4()}
                        textSize="fs-6"
                        showArrow={true}
                      />
                    ))}
                  </Row>
                </>
              )}
            </Container>
          </section>
        </Layout>
      </div>
    </>
  );
};

export default AllProjectsPage;
