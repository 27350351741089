export function cutOffAtWord(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
      }
    
      // Find the last space within the maxLength limit
      const truncated = str.slice(0, maxLength + 1); // Adding 1 to include the edge case where the next character is a space
      const lastSpaceIndex = truncated.lastIndexOf(' ');
    
      // If there's no space within the limit, truncate to maxLength
      if (lastSpaceIndex === -1) {
        return str.slice(0, maxLength) + '...';
      }
    
      // Otherwise, truncate at the last space
      return str.slice(0, lastSpaceIndex) + '...';
  }